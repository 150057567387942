import $ from 'jquery';
import 'dotenv/config';

const protocol = window.location.protocol;
const domain = window.location.hostname;
const baseURL = process.env.SERVER_URL
  ? process.env.SERVER_URL
  : `${protocol}//${domain}:3001`;

function checkLogin() {
  // Check if user has stored a token and validate it
  const token = localStorage.getItem('token');
  const url = `${baseURL}/api/users/validate`;
  if (token) {
    $.ajax({
      url: url,
      type: 'GET',
      contentType: 'application/json',
      dataType: 'json',
      crossDomain: true,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      success: function (data, textStatus) {
        // Handle the API response here
        if (textStatus === 'success') {
          location.href = 'mapa.html';
        } else {
          location.href = 'login.html';
        }
      },
      error: function (xhr, status, error) {
        // Handle errors here
        location.href = 'login.html';
      },
    });
  } else {
    location.href = 'login.html';
  }
}

$(function () {
  $('.start-icon').on('click', checkLogin);
});
